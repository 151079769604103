.wrapper {
  margin-bottom: 16px;
  .swiper-button-prev,
  .swiper-button-next {
    color: --var(--black);
    width: 0;
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 14px;
    font-weight: bold;
  }
}

.swiperWrapper {
  text-align: center;
  width: 100%;
}

.swiperContainer {
  font-size: 14px;
  color: --var(--black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 4px;
  min-height: 62px;

  svg {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }
}

.tooltip {
  display: flex;
}
