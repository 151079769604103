@use '@styles/functions' as func;
@use '@styles/mixins/breakpoints' as breakpoints;

.cLQuantityWrapper {
  display: flex;
  flex-direction: column;
  align-items: left;
  cursor: pointer;
  margin-bottom: 20px;

  > div {
    display: flex;
    align-items: center;
    margin-top: 20px;

    span {
      min-width: 124px;
      margin-right: 8px;
    }

    > div {
      width: 108px;
      padding: 1px;
      margin-left: 10px;

      button {
        background-color: var(--white);
      }
      li span {
        min-width: unset;
      }
    }
  }

  .subscriptionQuantityLabel {
    color: var(--text-primary-dark);
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 0;
    @include breakpoints.down(sm) {
      line-break: loose;
      hyphens: auto;
      overflow: hidden;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
      white-space: break-spaces;
    }
  }

  &.disableAllFields {
    pointer-events: none;
  }
}

.contactLensesSeparator {
  grid-column: span 3;
  height: 1px;
  background-color: var(--background-tertiary-light);
}

.styledSeparator {
  height: 1px;
  margin: 0;
  border: none;
  align-self: stretch;
  flex-grow: 0;
  background-color: var(--background-tertiary-light);
}

.contactLensesListHeadingContainer {
  background-color: var(--background-secondary-dark);
  display: flex;
  width: 100%;
  padding: 3px 0;
  position: relative;

  span {
    max-width: calc(50% - 2px);
  }

  &.Firefox span {
    max-width: calc(50% - 6px);
  }
}

.contactLensesListHeadingItem {
  text-align: center;
  width: 100%;
  :nth-of-type(odd) {
    border-right: 1px solid var(--background-dark-primary);
  }
}

.contactLensesDataColumn {
  grid-auto-rows: 1fr;
  display: grid;
  align-items: center;
  row-gap: 20px;
}

.contactLensesDataLabel {
  color: var(--text-dark-primary);
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;

  @include breakpoints.down(sm) {
    line-break: loose;
    hyphens: auto;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    white-space: break-spaces;
  }
}

.contactLensesDataWrapper {
  display: grid;
  grid-template-columns: 2fr 3fr 3fr;
  gap: 20px;
  row-gap: 16px;
  cursor: pointer;

  div {
    pointer-events: inherit;
  }
  &.disabledFields {
    div {
      pointer-events: none;
    }
  }
}

.contactLensesSelectPrescriptionTitle {
  font-size: 0.875rem;
  color: var(--text-primary-dark);
  text-transform: uppercase;
  font-weight: bold;
}

.contactLensesInput {
  height: 33px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--boulder);

  &.readOnly {
    color: var(--primary-light);
    background-color: var(--background-quinary-light);
    &:focus {
      outline: none;
    }
  }
  &.disabled {
    opacity: 0.5;
  }
  &.default {
    background-color: var(--background-primary-light);
  }
}
